import BaseSidePanel from 'js/base_v2/side-panel';
import BaseDataTable from 'js/base_v2/data-table';
import ConfirmationForm from 'js/components/confirmation-form';

const staticSelf = BaseSelectionPanel;

/**
 * @const
 */
staticSelf.ACTION_UPDATE_RECORD = 'updateRecord';

/**
 * @const
 */
staticSelf.ACTION_REMOVE = 'remove';

/**
 * Base Side Panel.
 *
 * @abstract
 * @extends BaseSidePanel
 *
 * @param {object} [options]
 */
function BaseSelectionPanel(options) {
  BaseSidePanel.call(this, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {array}
   */
  this.records = [];

  /**
   * @prop {array}
   */
  this.groups = [];

  /**
   * @prop {int}
   */
  this.distinctGroupCount = 0;

  /**
   * @prop {object}
   */
  this.recordFilters = null;

  /**
   * @prop {string}
   */
  this.selectedRecordsMode = null;

  /**
   * @prop {?number}
   */
  this.recordsCount = null;

  /**
   * @prop {number}
   */
  this.totalActiveRecordsCount = null;

  /**
   * @prop {int[]}
   */
  this.excludeIds = [];

  /**
   * @prop {?object}
   */
  this.recordsViewBy = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      container: `
        <div class="jsRowTools wrap_tools_table" style="display: none;"></div>
      `,
      parentContainer: 'body div.wrap_top_block',
      groupField: null,
      afterDelete() {},
      saveFormClass: undefined,
      saveForm: {
        afterSubmit() {
          self.onActionSuccess();
        },
      },
      removeConfirmationClass: undefined,
      removeConfirmation: {
        onAction() {
          self.onActionSuccess();
        },
      },
      onActionSuccess: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.prepareTmplParams = function() {
    const params = {
      records: this.records,
      groups: this.groups,
      translator: this.translator,
    };

    const countSelectedText = [];
    let selectedRecordsCount;

    if (this.records.length > 0) {
      const excludedCount = this.getExcludeIds().length;

      if (this.selectedRecordsMode === BaseDataTable.SELECTION_ALL &&
        excludedCount === 0
      ) {
        countSelectedText.push(this.translator.get('all'));
      }

      selectedRecordsCount = this.getRecordsCount();

      if (this.selectedRecordsMode === BaseDataTable.SELECTION_ALL ||
        this.selectedRecordsMode === BaseDataTable.SELECTION_FILTERED
      ) {
        selectedRecordsCount -= excludedCount;
      }

      countSelectedText.push(selectedRecordsCount);

      if (this.selectedRecordsMode === BaseDataTable.SELECTION_DISPLAYED) {
        countSelectedText.push(this.translator.get('displayed'));
      // eslint-disable-next-line max-len
      } else if (this.selectedRecordsMode === BaseDataTable.SELECTION_FILTERED) {
        countSelectedText.push(this.translator.get('filtered'));
      }

      countSelectedText.push(this.getEntityLabel());

      if (this.options.groupField) {
        const groupLabel = this.getEntityGroupLabel(this.distinctGroupCount);

        countSelectedText.push('from');
        countSelectedText.push(this.distinctGroupCount);
        countSelectedText.push(groupLabel);
      }
    }

    countSelectedText.push(this.groups.length > 1 ||
      (selectedRecordsCount > 1 ? 'have' : 'has'));
    countSelectedText.push('been selected');

    params.countSelectedText = countSelectedText.join(' ');

    return params;
  };

  /**
   * Get action names allowed given the current record
   *
   * @return {string[]}
   */
  this.getAllowedActions = function() {
    return [];
  };

  /**
   * @inheritDoc
   */
  this.setRecord = function(record) {
    parent.setRecord.call(this, record);

    if (_.isObject(record) &&
      !_.isUndefined(record.record) &&
      !_.isUndefined(record.group)
    ) {
      this.records = record.record;
      this.groups = record.group;
    } else if (_.isArray(record)) {
      this.records = record;
    } else {
      throw new Error('BaseSelectionPanel.setRecord: Invalid record.');
    }

    if (this.options.groupField) {
      const groupIds = _.map(self.records, (r) => r[self.options.groupField]);

      this.distinctGroupCount = _.unique(groupIds).length;
    }

    return this;
  };

  /**
   * Show selection panel.
   *
   * @param  {object}             record
   * @param  {object}             recordFilters
   * @param  {string}             selectedRecordsMode
   * @param  {?number}            recordsCount
   * @param  {number}             totalActiveRecordsCount
   * @param  {array}              excludeIds
   * @param  {?object}            [recordsViewBy]
   * @return {BaseSelectionPanel}
   */
  this.show = function(
    record,
    recordFilters,
    selectedRecordsMode,
    recordsCount = null,
    totalActiveRecordsCount,
    excludeIds = [],
    recordsViewBy = null,
  ) {
    this.setRecord(record);
    this.setRecordFilters(recordFilters);
    this.setSelectedRecordsMode(selectedRecordsMode);
    this.setRecordsCount(recordsCount);
    this.setTotalActiveRecordsCount(totalActiveRecordsCount);
    this.setExcludeIds(excludeIds);
    this.setRecordsViewBy(recordsViewBy);

    parent.show.call(this, record);

    this.panelCt.find('a[class^="action"]').addClass('disable');

    const allowedActions = this.getAllowedActions();

    _.each(allowedActions, function(action) {
      this.panelCt.find(`a.action${action.capitalize()}`)
        .removeClass('disable');
    }, this);

    return this;
  };

  /**
   * Set record filters.
   *
   * @param  {object}             recordFilters
   * @return {BaseSelectionPanel}
   */
  this.setRecordFilters = function(recordFilters) {
    this.recordFilters = recordFilters;

    return this;
  };

  /**
   * Get record filters.
   *
   * @return {object}
   */
  this.getRecordFilters = function() {
    return this.recordFilters;
  };

  /**
   * Set selected records mode.
   *
   * @param  {string}             mode
   * @return {BaseSelectionPanel}
   */
  this.setSelectedRecordsMode = function(mode) {
    this.selectedRecordsMode = mode;

    return this;
  };

  /**
   * Get selected records mode.
   *
   * @return {string}
   */
  this.getSelectedRecordsMode = function() {
    return this.selectedRecordsMode;
  };

  /**
   * Get selected record IDs.
   *
   * @return {?(number[])}
   */
  this.getSelectedRecordIds = function() {
    if (!_.isEmpty(this.selectedRecordsMode) &&
      BaseDataTable.SELECTION_DISPLAYED !== this.selectedRecordsMode
    ) {
      return null;
    }

    return _.map(this.records, (record) => parseInt(record.id, 10));
  };

  /**
   * Set records count.
   *
   * @param  {number}             count
   * @return {BaseSelectionPanel}
   */
  this.setRecordsCount = function(count) {
    this.recordsCount = count;

    return this;
  };

  /**
   * Get records count.
   *
   * @return {number}
   */
  this.getRecordsCount = function() {
    switch (this.selectedRecordsMode) {
      case BaseDataTable.SELECTION_ALL:
      case BaseDataTable.SELECTION_FILTERED:
        return this.recordsCount;
      case BaseDataTable.SELECTION_DISPLAYED:
      default:
        return this.records?.length || 0;
    }
  };

  /**
   * Set total active records count.
   *
   * @param  {number}             totalActiveRecordsCount
   * @return {BaseSelectionPanel}
   */
  this.setTotalActiveRecordsCount = function(totalActiveRecordsCount) {
    this.totalActiveRecordsCount = totalActiveRecordsCount;

    return this;
  };

  /**
   * Get total active records count.
   *
   * @return {number}
   */
  this.getTotalActiveRecordsCount = function() {
    return this.totalActiveRecordsCount;
  };

  /**
   * Set exclude ids.
   *
   * @param  {array}              excludeIds
   * @return {BaseSelectionPanel}
   */
  this.setExcludeIds = function(excludeIds) {
    this.excludeIds = excludeIds;

    return this;
  };

  /**
   * Get exclude ids.
   *
   * @return {number}
   */
  this.getExcludeIds = function() {
    return this.excludeIds;
  };

  /**
   * Set records view by.
   *
   * @param  {?object}            recordsViewBy
   * @return {BaseSelectionPanel}
   */
  this.setRecordsViewBy = function(recordsViewBy) {
    this.recordsViewBy = recordsViewBy;

    return this;
  };

  /**
   * @inheritDoc
   */
  this.displayContainer = function(animate = true) {
    if (animate) {
      this.panelCt.slideDown();
    } else {
      this.panelCt.show();
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.hideContainer = function(animate = true) {
    if (animate) {
      this.panelCt.slideUp();
    } else {
      this.panelCt.hide();
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.panelCt.on('click', 'a[class^="action"]', function(e) {
      e.preventDefault();

      const action = $(this).attr('class').split(' ')[0].substr('6');
      const fName = `action${action}`;

      if (_.isFunction(self[fName])) {
        self[fName]();
      }
    });

    return this;
  };

  /**
   * Action success event handler.
   */
  this.onActionSuccess = function() {
    this.hide();

    if (_.isFunction(this.options.onActionSuccess)) {
      this.options.onActionSuccess();
    }
  };

  /**
   * @return {object}
   */
  this.getServerParams = function() {
    const params = Object.extend({}, self.options.serverParams);

    params[this.options.entityName] = _.map(this.records, (r) => r.id);

    if (this.options.entityGroupName) {
      params[this.options.entityGroupName] = _.map(this.groups, (r) => r.id);
    }

    return params;
  };

  /**
   * @param  {int}    count
   * @return {string}
   */
  this.getEntityLabel = function(count) {
    const recordsCnt = count || this.records.length;

    return recordsCnt === 1 ?
      this.options.entityLabel :
      this.options.entitiesLabel;
  };

  /**
   * @param  {int}    count
   * @return {string}
   */
  this.getEntityGroupLabel = function(count) {
    const groupsCnt = count || this.groups.length;

    return groupsCnt === 1 ?
      this.options.entityGroupLabel :
      this.options.entitiesGroupLabel;
  };

  /**
   * Count record by criterion.
   *
   * @param  {object} criterion
   * @return {number}
   */
  this.countRecordsByCriterion = function(criterion) {
    let count = 0;

    _.each(this.records, (record) => {
      // eslint-disable-next-line eqeqeq
      if (criterion.value == record[criterion.key]) {
        count += 1;
      }
    });

    return count;
  };

  /**
   * Update record action handler.
   *
   * @return {BaseSelectionPanel}
   */
  this.actionUpdateRecord = function() {
    if (!_.isFunction(this.options.saveFormClass)) {
      return this;
    }

    new this.options.saveFormClass(
      this.records[0],
      this.options.saveForm,
    ).create();

    return this;
  };

  /**
   * Remove (records) action handler.
   *
   * @return {BaseSelectionPanel}
   */
  this.actionRemove = function() {
    if (!_.isFunction(this.options.removeConfirmationClass)) {
      return this;
    }

    new this.options.removeConfirmationClass(
      this.records,
      this.options.removeConfirmation,
    ).create();

    return this;
  };

  /**
   * Delete action handler.
   *
   * @deprecated
   *
   * @return {BaseSelectionPanel}
   */
  this.actionDelete = function() {
    new ConfirmationForm(self.record, {
      actionType: 'remove',
      serverParams: this.getServerParams(),
      tmplParams: {
        actionUrl: self.options.deleteUrl,
      },
      afterSubmit(r) {
        self.options.afterUpdate(r);
        self.hide();
      },
    }).create();
  };
}

export default BaseSelectionPanel;
