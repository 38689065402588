import BaseComponent from 'js/base_v2/component';
import DefaultNotifier from 'js/components_v2/default-notifier';
/**
 * Base Side Panel.
 *
 * @abstract
 * @extends BaseComponent
 *
 * @param {object} [options]
 */
function BaseSidePanel(options) {
    BaseComponent.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @const
     */
    this.HIDE_EVENT_NAME = 'partfiniti.sidePanel.hide';

    /**
     * @prop {DefaultNotifier}
     */
    this.notifier = null;

    /**
     * @prop {object}
     */
    this.record = null;

    /**
     * @prop {object}
     */
    this.tmplParams = {};

    /**
     * @prop {boolean}
     */
    this.closing = false;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            saveFormGroupClass: null,
            saveFormGroupOptions: {},
            saveFormClass: null,
            saveFormOptions: {},
            parentContainer: 'body',
            entityLabel: 'record',
            entitiesLabel: 'records',
            entityGroupLabel: 'group',
            entitiesGroupLabel: 'groups',
            onHide: undefined,
            notifier: {}
        });
    };

    /**
     * @inheritDoc
     */
    this.create = function() {
        parent.create.call(this);

        return this
            .createContainer()
            .registerEventListeners();
    };

    /**
     * @inheritDoc
     */
    this.createSubcomponents = function() {
        parent.createSubcomponents.call(this);
        this.notifier = new DefaultNotifier(this.options.notifier).create();

        return this;
    };

    /**
     * @inheritDoc
     */
    this.registerEventListeners = function() {
        this.panelCt.on('click', '.closePanel', function(event) {
            event.preventDefault();
            self.hide();
        });

        this.panelCt.on('click', '.jsEditEntity', function(event) {
            event.preventDefault();
            self.createEditForm();
        });

        this.panelCt.on(this.HIDE_EVENT_NAME, function() {
            self.onHide();
        });

        return this;
    };

    /**
     * @return {object}
     */
    this.getRecord = function() {
        return this.record;
    };

    /**
     * @return {BaseSidePanel}
     */
    this.createEditForm = function() {
        var formClass, formOptions,
            formRecord = this.getRecord();

        if (this.options.saveFormGroupClass) {
            formClass = this.options.saveFormGroupClass;
            formOptions = this.options.saveFormGroupOptions;
        } else {
            formClass = this.options.saveFormClass;
            formOptions = this.options.saveFormOptions;
        }

        formOptions = Object.extend(formOptions, {
            afterSubmit: function(r) {
                var record = r[self.options.entityName];
                self.onAfterUpdate(record, r);
            }
        });

        return new formClass(formRecord, formOptions).create();
    };

    /**
     * @param  {object} record
     * @param  {object} response
     * @return {BaseSidePanel}
     */
    this.onAfterUpdate = function(record, response) {
        this.show(record);

        return this;
    };

    /**
     * @param  {object} record
     * @return {BaseSidePanel}
     */
    this.setRecord = function(record) {
        this.record = record;

        return this;
    };

    /**
     * @return {object}
     */
    this.prepareTmplParams = function() {
        return this.record;
    };

    /**
     * Show.
     *
     * @param  {object}        record
     * @return {BaseSidePanel}
     */
    this.show = function(record) {
        var animate = true;

        if (this.isOpened()) {
            animate = false;
            this.hide(animate, false);
        }

        this.setRecord(record);

        this.tmplParams = this.prepareTmplParams();

        var tmplSelector = '#' + this.options.template;
        var content = $(tmplSelector).tmpl(this.tmplParams);

        this.panelCt.html('');
        this.panelCt.append(content);

        this.displayContainer(animate);

        return this;
    };

    /**
     * @return {BaseSidePanel}
     */
    this.createContainer = function() {
        this.panelCt = $(this.options.container);
        this.panelCt.prependTo($(this.options.parentContainer));

        return this;
    };

    /**
     * Hide.
     *
     * @param  {boolean}       animate
     * @param  {boolean}       trigger
     * @return {BaseSidePanel}
     */
    this.hide = function(animate, trigger) {
        animate = _.isUndefined(animate) ? true : animate;
        trigger = _.isUndefined(trigger) ? true : trigger;

        this.hideContainer(animate, trigger);

        if (trigger) {
            this.panelCt.trigger('partfiniti.sidePanel.hide');
        }

        this.record = null;

        return this;
    };

    /**
     * Display container.
     *
     * @param  {boolean}       animate
     * @return {BaseSidePanel}
     */
    this.displayContainer = function(animate) {
        return this;
    };

    /**
     * Hide container.
     *
     * @param  {boolean}       animate
     * @return {BaseSidePanel}
     */
    this.hideContainer = function(animate) {
        return this;
    };

    /**
     * Determine whether the panel is opened.
     *
     * @return {boolean}
     */
    this.isOpened = function() {
        return !this.closing && this.panelCt.is(':visible');
    };

    /**
     * Hide event handler.
     *
     * @return {boolean|undefined}
     */
    this.onHide = function() {
        if (_.isFunction(this.options.onHide) &&
            false === this.options.onHide(this.record)
        ) {
            return false;
        }

        this.closing = true;

        return undefined;
    };
}

export default BaseSidePanel;
