import BaseSaveForm from 'js/base_v2/save-form';

/**
 * Base Bulk Save Form.
 *
 * @class
 * @abstract
 * @extends BaseSaveForm
 *
 * @param {object[]} records
 * @param {object}   [options]
 */
function BaseBulkSaveForm(records, options) {
  BaseSaveForm.call(this, null, options);
  const parent = this.clone();

  /**
   * @prop {object[]}
   */
  this.records = records || [];

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entitiesName: 'records',
      entitiesLabel: 'records',
      focusFieldActionTypes: [
        this.ACTION_TYPE_CREATE,
        this.ACTION_TYPE_UPDATE,
        this.ACTION_TYPE_ADD,
      ],
    });
  };

  /**
   * @inheritDoc
   */
  this.initActionType = function() {
    this.options.actionType = this.records.length > 0 ?
      this.ACTION_TYPE_UPDATE :
      this.options.actionType;

    return this;
  };

  /**
   * @inheritDoc
   */
  this.getLabel = function() {
    if (1 === this.records.length) {
      return this.options.entityLabel;
    }

    return this.options.entitiesLabel;
  };
}

export default BaseBulkSaveForm;
