import BaseFieldGroup from 'js/base_v2/field-group';
import DateField from 'js/base_v2/fields/date-field';

export default function DateIntervalFieldGroup(fieldGroupCt, options) {
  BaseFieldGroup.call(this, fieldGroupCt, options);
  const parent = this.clone();
  const self = this;

  /**
   * @inheritDoc
   */
  this.fieldParams = [{
    name: 'sinceDateField',
    className: DateField,
    fieldEl: '.sinceDateField',
  }, {
    name: 'untilDateField',
    className: DateField,
    fieldEl: '.untilDateField',
  }];

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    this.defaultOptions = Object.extend(this.defaultOptions, {
      sinceDateField: {
        datepicker: {
          onClose(dateString) {
            self.onSinceDatepickerClose(dateString);
          },
        },
      },
      untilDateField: {
        datepicker: {
          onClose(dateString) {
            self.onUntilDatepickerClose(dateString);
          },
        },
      },
    });

    return this;
  };

  /**
   * Since datepicker close event handler.
   *
   * @param {string} dateString
   */
  this.onSinceDatepickerClose = function(dateString) {
    this.fields.untilDateField.setMinDate(dateString);
  };

  /**
   * Until datepicker close event handler.
   *
   * @param {string} dateString
   */
  this.onUntilDatepickerClose = function(dateString) {
    this.fields.sinceDateField.setMaxDate(dateString);
  };

  /**
   * Get since date field value.
   *
   * @return {moment|null}
   */
  this.getSinceDate = function() {
    return this.getDate(this.fields.sinceDateField.getValue());
  };

  /**
   * Get until date field value.
   *
   * @return {moment|null}
   */
  this.getUntilDate = function() {
    return this.getDate(this.fields.untilDateField.getValue());
  };

  /**
   * Get date from string.
   *
   * @param {mixed} dateValue
   *
   * @return {moment|null}
   */
  this.getDate = function(dateValue) {
    if (_.isUndefined(dateValue)) {
      return null;
    }

    const date = moment(dateValue);

    return date.isValid() ? date : null;
  };

  // Initialize
  this.init();
}
