import BaseField from 'js/base_v2/field';

/**
 * Date Field.
 *
 * @class
 * @extends BaseComponent
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     options
 */
function DateField(fieldCt, fieldEl, options) {
  BaseField.call(this, fieldCt, fieldEl, options);
  const parent = this.clone();
  const self = this;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    this.extendDefaultOptions({
      daterangepicker: {
        initialDate: null,
        autoApply: false,
        singleDatePicker: true,
        showDropdowns: true,
        isClearable: true,
        drops: 'auto',
        locale: {
          format: 'YYYY-MM-DD',
        },
      },
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);

    this.createClearDateBtn();

    return this.createDatePicker();
  };

  /**
   * Create clear date button.
   */
  this.createClearDateBtn = function() {
    if (this.options.daterangepicker.isClearable) {
      this.fieldEl.after(
        '<a href="#" class="clearDate clear-date-icon-ct">' +
          '<svg><use xlink:href="#icon-remove"></use></svg>' +
        '</a>',
      );
    }
  };

  /**
   * Create date picker.
   *
   * @return {DateField}
   */
  this.createDatePicker = function() {
    const initialDate = this.options.daterangepicker.initialDate ?
      moment(this.options.daterangepicker.initialDate) : null;

    this.dateRangePicker = this.fieldEl.daterangepicker(
      this.options.daterangepicker,
      (date) => {
        self.update(date);
      },
    );

    this.update(initialDate);

    return this;
  };

  /**
   * Update date picker.
   *
   * @param  {object} newOptions
   * @return {DateField}
   */
  this.updateDatePicker = function(newOptions) {
    this.dateRangePicker = this.fieldEl.daterangepicker(
      this.options.daterangepicker.extend(newOptions),
    );

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.fieldEl.parent().on('click', '.clearDate', (e) => {
      self.onClearDateBtnClick(e);
    });

    this.fieldEl.on('hide.daterangepicker', (event) => {
      self.onDatePickerHide(event);
    });

    return this;
  };

  /**
   * Clear date button click event handler.
   *
   * @param {Event} e
   */
  this.onClearDateBtnClick = function(e) {
    e.preventDefault();

    this.update(null);
  };

  /**
   * Hide date range picker event handler.
   *
   * @param  {Event}             event
   * @return {boolean|undefined}
   */
  this.onDatePickerHide = function(event) {
    if (_.isFunction(this.options.onDatePickerHide) &&
      false === this.options.onDatePickerHide(event)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Update start date.
   *
   * @param  {moment|null} date
   * @return {DateField}
   */
  this.update = function(date) {
    const clearDateBtn = this.fieldEl.next('.clearDate') || null;

    if (null === date) {
      this.fieldEl.val('');

      clearDateBtn?.hide();

      return this;
    }

    this.fieldEl.val(date.format(
      this.options.daterangepicker.locale.format,
    ));

    clearDateBtn?.show();

    return this;
  };

  // Initialize
  this.init();
}

export default DateField;
