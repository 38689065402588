import BaseFilter from 'js/base_v2/filter';

const staticSelf = DateRangeFilter;

/**
 * @const
 */
staticSelf.BEGINNING_YEAR = 1900;

/**
 * Date Range Filter.
 *
 * @class
 * @extends BaseFilter
 *
 * @param {DOMElement} filterEl
 * @param {object}   [options]
 */
function DateRangeFilter(filterEl, options) {
  BaseFilter.call(this, filterEl, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {string}
   */
  this.ranges = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      triggerEvent: 'change',
      onChange: undefined,
      ranges: undefined,
      startDate: undefined,
      endDate: undefined,
      autoUpdateInput: true,
      hasAllRange: true,
      allRangeEmpty: true,
      alwaysShowCalendars: false,
      onlyCustomRanges: false,
      initialRange: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    return this.processInitialDateRangeOptions();
  };

  /**
   * Process initial date range options.
   *
   * @return {DateRangeFilter}
   */
  this.processInitialDateRangeOptions = function() {
    if (this.options.initialValue) {
      const initialDatePieces = this.options.initialValue.split('|');

      this.options.startDate = moment(initialDatePieces[0], 'MM/DD/YY');
      this.options.endDate = moment(initialDatePieces[1], 'MM/DD/YY');
    }

    if (!_.isEmpty(this.options.initialRange) &&
      _.isObject(this.options.ranges)
    ) {
      const rangeValues = this.options.ranges[this.options.initialRange];

      this.options.startDate = moment(rangeValues[0], 'MM/DD/YY');
      this.options.endDate = moment(rangeValues[1], 'MM/DD/YY');
    }

    if (this.options.hasAllRange) {
      this.options.ranges = {
        All: [moment().add(1, 'days'), moment().add(1, 'days')],
        ...(this.options.ranges || {}),
      };
    }

    if (this.options.onlyCustomRanges) {
      return this;
    }

    this.options.originalStartDate = this.options.startDate;

    if (_.isUndefined(this.options.startDate)) {
      if (!_.isObject(this.options.ranges) ||
        _.isUndefined(this.options.ranges.All)
      ) {
        this.options.startDate = moment();
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.options.startDate = this.options.ranges.All[0];
      }
    }

    this.options.originalEndDate = this.options.endDate;

    if (_.isUndefined(this.options.endDate)) {
      if (!_.isObject(this.options.ranges) ||
        _.isUndefined(this.options.ranges.All)
      ) {
        this.options.endDate = moment();
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.options.endDate = this.options.ranges.All[1];
      }
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);

    return this.createDateRangePicker();
  };

  /**
   * Create date range picker.
   *
   * @return {DateRangePicker}
   */
  this.createDateRangePicker = function() {
    this.filterEl.daterangepicker({
      autoUpdateInput: this.options.autoUpdateInput,
      alwaysShowCalendars: this.options.alwaysShowCalendars,
      startDate: this.options.startDate,
      endDate: this.options.endDate,
      ranges: self.getDateRanges(),
    }, function(start, end) {
      self.update(this, start, end);
    });

    this.update(null, this.options.startDate, this.options.endDate);

    return this;
  };

  /**
   * Update.
   *
   * @param  {?object}     dateRangePicker
   * @param  {moment}      start
   * @param  {moment}      end
   * @return {DateRangeFilter}
   */
  this.update = function(dateRangePicker, start, end) {
    const startDate = Number.isNaN(Date.parse(start)) ? null : start;
    const endDate = Number.isNaN(Date.parse(end)) ? null : end;

    const chosenLabel = Object.get(dateRangePicker, 'chosenLabel');
    const isRangeInitialized = !_.isEmpty(this.options.originalStartDate) &&
      !_.isEmpty(this.options.originalEndDate);

    // eslint-disable-next-line max-len
    if (((null === chosenLabel && !isRangeInitialized) || 'All' === chosenLabel) &&
      this.options.allRangeEmpty
    ) {
      this.range = null;
      this.renderEmptyDateRangePicker();
    } else {
      const startStr = _.isObject(startDate) ?
        startDate.format('MM/DD/YY') :
        '';

      const endStr = _.isObject(endDate) ?
        endDate.format('MM/DD/YY') :
        '';

      this.range = `${startStr}|${endStr}`;

      $('.datePickerLabel', this.filterEl).hide();

      this.renderNonEmptyDateRangePicker(chosenLabel, startStr, endStr);
    }

    this.trigger();
    this.onChange();

    return this;
  };

  /**
   * Render empty date range picker.
   */
  this.renderEmptyDateRangePicker = function() {
    $('.dateRangePickerSpan', this.filterEl).html('');
    $('.datePickerLabel', this.filterEl).show();
  };

  /**
   * Render non-empty date range picker.
   *
   * @param  {string}          chosenLabel
   * @param  {string}          startStr
   * @param  {string}          endStr
   * @return {DateRangeFilter}
   */
  this.renderNonEmptyDateRangePicker = function(chosenLabel, startStr, endStr) {
    if ('Today' === chosenLabel || endStr === startStr) {
      $('.dateRangePickerSpan', this.filterEl).html(startStr);

      return this;
    }

    if (!_.isEmpty(startStr) && _.isEmpty(endStr)) {
      $('.dateRangePickerSpan', this.filterEl).html(
        `${this.translator.getTitle('label_since')} ${startStr}`,
      );

      return this;
    }

    if (_.isEmpty(startStr) && !_.isEmpty(endStr)) {
      $('.dateRangePickerSpan', this.filterEl).html(
        `${this.translator.getTitle('label_until')} ${endStr}`,
      );

      return this;
    }

    $('.dateRangePickerSpan', this.filterEl).html(`${startStr} - ${endStr}`);

    return this;
  };

  /**
   * Change event handler.
   *
   * @return {boolean|undefined}
   */
  this.onChange = function() {
    if (_.isFunction(this.options.onChange) &&
      false === this.options.onChange()
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * @inheritDoc
   */
  this.clear = function() {
    parent.clear.call(this);

    $('.dateRangePickerSpan', this.filterEl).val(null);

    return this.trigger();
  };

  /**
   * Get date ranges.
   *
   * @return {object}
   */
  this.getDateRanges = function() {
    if (_.isObject(this.options.ranges)) {
      return this.options.ranges;
    }

    return {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    };
  };

  /**
   * @inheritDoc
   */
  this.getValue = function() {
    return this.range;
  };

  // Initialize
  this.init();
}

export default DateRangeFilter;
