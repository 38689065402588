import BaseFilter from 'js/base_v2/filter';

/**
 * Select Filter.
 *
 * @class
 * @extends BaseFilter
 *
 * @param {DOMElement} filterEl
 * @param {object}     [options]
 */
function SelectFilter(filterEl, options) {
  BaseFilter.call(this, filterEl, options);
  const parent = this.clone();

  /**
   * @prop {BaseSelect2Field}
   */
  this.field = null;

  /**
   * @inheritDoc
   */
  this.processInitialValueOptions = function() {
    parent.processInitialValueOptions.call(this);

    return this.extendOptions({
      fieldOptions: {
        initialValue: this.options.initialValue,
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.createField = function() {
    parent.createField.call(this);

    this.field = new this.options.fieldClassName(
      this.filterEl.closest('.selectCt'),
      this.filterEl,
      this.options.fieldOptions,
    ).create();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.reset = function() {
    parent.reset.call(this);

    this.field.setValue(!_.isUndefined(this.options.defaultValue) ?
      this.options.defaultValue :
      null);

    return this;
  };

  /**
   * @inheritDoc
   */
  this.getFullValue = function() {
    const value = this.field.getValue();

    const getRawValue = (v) => ((_.isObject(v) && _.isFunction(v.getData)) ?
      v.getData() :
      v);

    return _.isArray(value) ?
      value.map((v) => getRawValue(v)) :
      getRawValue(value);
  };

  // Initialize
  this.init();
}

export default SelectFilter;
